import React, { useState } from 'react';

import { request } from '@jvs-group/jvs-mairistem-store';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import { ErrorField } from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import { Combobox, TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { isNewIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { isError } from '@/src/utils/tools';
import ProjetComposantInput
  from '@/src/components/SubventionFichePanel/Generalite/SubventionProjet/ProjetComposantInput';
import { Projet } from '@/src/interfaces/projet';
import { Composant } from '@/src/interfaces/composant';
import { getAssietteEligible } from '@/src/utils/subvention';

interface SubventionProjetProps {
  projet: Projet,
  selectedComposants: Partial<Composant>[];
  identifiantEntite: number,
  errors: Array<ErrorField>,
  onChange: any,
  identifiant: number,
  plafondEligible: number,
  realisationHT: boolean,
  tauxAide: number,
}

const SubventionProjet = ({
  projet: projetProps = null,
  selectedComposants = null,
  identifiantEntite = null,
  errors = null,
  onChange = null,
  identifiant = null,
  plafondEligible = 0,
  realisationHT = false,
  tauxAide = 0,
} : SubventionProjetProps) => {
  const [projets, setProjets] = useState([]);
  const [projet, setProjet] = useState(null);

  React.useEffect(() => {
    setProjet(projetProps);
  }, [projetProps, identifiant]);

  React.useEffect(() => {
    if (identifiantEntite) {
      request.get<unknown, TemplateRequestGet<Projet>>(
        // eslint-disable-next-line max-len
        `/api/finances/subventionsPercues/projets/?identifiantEntite=${identifiantEntite}&all=true&sort=libelle:asc&filterOnStatus=true${!isNewIdentifiant(identifiant) && `&identifiantSubvention=${identifiant}`}`,
      )
      // Récupération des projets non cloturés et du projet sur la subvention même si il est cloturé
        .then(({ data }) => {
          setProjets(data.map(
            (newProjet) => (
              {
                key: newProjet.identifiant,
                value: newProjet.identifiant,
                text: newProjet.libelle,
              }),
          ));
        });
    }
  }, [identifiantEntite, identifiant]);

  const handleChange = (e, { value }) => {
    request.get<unknown, Projet>(`/api/finances/subventionsPercues/projets/${value}?withComposants`)
      .then((data) => {
        setProjet(data);

        if (onChange) {
          const assietteEligible = getAssietteEligible(
            plafondEligible,
            data?.composants,
            realisationHT,
          );

          onChange({
            identifiant,
            identifiantProjet: data?.identifiant,
            projet: data,
            composants: data?.composants,
            assietteEligible,
            montantEnvisage: (assietteEligible * tauxAide) / 100,
          });
        }
      });
  };

  return (
    <>
      <Form.Field
        control={Combobox}
        data-testid="projetSubvention"
        error={isError(errors, 'subventionspercues-projet')}
        // @ts-expect-error
        fluid
        id="subventionspercues-projet"
        key="projet"
        label="Projet associé"
        name="projet"
        onChange={handleChange}
        options={projets}
        placeholder="Projet associé"
        required
        search
        selection
        value={projet?.identifiant ?? null}
      />

      {projet?.composants?.length > 1 && (
        <ProjetComposantInput
          composants={projet?.composants}
          selectedComposants={selectedComposants}
          onChange={onChange}
          identifiant={identifiant}
          plafondEligible={plafondEligible}
          realisationHT={realisationHT}
          tauxAide={tauxAide}
        />
      ) }
    </>
  );
};

export default SubventionProjet;
