import React from 'react';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import '@/src/components/Planning/planning.less';

const PlanningLegend = () => (
  <>
    <div className="bold legendTitle">Légende</div>
    <div className="legendContainer">
      <div className="legendItems">
        <Icon name="circle" color="blue" size="big" />
        {' '}
        Conception
      </div>
      <div className="legendItems">
        <Icon name="circle" color="pink" size="big" />
        {' '}
        Planification
      </div>
      <div className="legendItems">
        <Icon name="circle" color="green" size="big" />
        {' '}
        Exécution
      </div>
      <div className="legendItems">
        <Icon name="circle" color="grey" size="big" />
        {' '}
        Clôturé
      </div>
    </div>
  </>
);

export default PlanningLegend;
