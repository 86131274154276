import React from 'react';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import {
  Input, Grid, Segment, Currency, Tooltip,
} from '@jvs-group/jvs-mairistem-composants';
import {
  find, isNil, some,
} from 'lodash';
import { Composant } from '@/src/interfaces/composant';
import { ProjetStatus } from '@/src/constants/projetStatus';

interface ProjetComposantRowProps {
    composant : Composant,
    rowProps,
    utilities,
    errors,
    status : number,
}

const getTooltipError = (errors, id) => {
  const error = find(errors, (e) => e.field === id);
  if (!isNil(error)) {
    return {
      type: 'error',
      message: error.message,
    };
  }

  return null;
};

const ProjetComposantRow = ({
  composant = null,
  rowProps,
  utilities,
  errors,
  status,
} : ProjetComposantRowProps) => {
  const handleChange = (e, data) => {
    const { name, value } = data;

    utilities?.onChange({ [name]: value });
  };

  const handleChangeCurrency = ({ currentTarget }, data) => {
    utilities?.onChange({ [currentTarget?.name]: isNil(data) || data === '' ? 0 : data });
  };

  return (
    <ListeTable.Row
      key={composant?.identifiant}
      error={some(errors, { itemId: composant?.identifiant })}
      {...rowProps}
    >
      <ListeTable.Cell name="libelle">
        <Grid padded={false}>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Tooltip
                position="top center"
                {...getTooltipError(errors, 'libelle')}
                trigger={(
                  <Input
                    name="libelle"
                    fluid
                    error={some(errors, { itemId: composant?.identifiant, field: 'libelle' })}
                    transparent
                    placeholder="Libelle"
                    value={`${composant?.libelle ?? ''}`}
                    onChange={handleChange}
                  >
                    <input data-testid={`libelleComposante-${composant?.identifiant}`} />
                  </Input>
                )}
              />
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Segment basic compact className="no-padding">HT</Segment>
              <Segment basic compact className="no-padding">TTC</Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ListeTable.Cell>

      <ListeTable.Cell name="montantEstime">
        <Grid>
          <Grid.Row stretched>
            <Grid.Column verticalAlign="middle">
              <Currency
                name="montantEstimeHt"
                value={composant?.montantEstimeHt}
                transparent
                fluid
                onChange={handleChangeCurrency}
              >
                <input
                  data-testid={`montantEstimeHt-${composant?.identifiant}`}
                  className="currencyInput"
                />
              </Currency>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column verticalAlign="middle">
              <Currency
                name="montantEstimeTtc"
                value={composant?.montantEstimeTtc}
                transparent
                fluid
                onChange={handleChangeCurrency}
              >
                <input
                  data-testid={`montantEstimeTtc-${composant?.identifiant}`}
                  className="currencyInput"
                />
              </Currency>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ListeTable.Cell>

      {status !== ProjetStatus.CONCEPTION && (
      <>
        <ListeTable.Cell name="montantInitial">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Currency
                  name="montantInitialHt"
                  value={composant?.montantInitialHt}
                  transparent
                  fluid
                  onChange={handleChangeCurrency}
                >
                  <input
                    data-testid={`montantInitialHt-${composant?.identifiant}`}
                    className="currencyInput"
                  />
                </Currency>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Currency
                  name="montantInitialTtc"
                  value={composant?.montantInitialTtc}
                  transparent
                  fluid
                  onChange={handleChangeCurrency}
                >
                  <input
                    data-testid={`montantInitialTtc-${composant?.identifiant}`}
                    className="currencyInput"
                  />
                </Currency>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ListeTable.Cell>
        <ListeTable.Cell name="montantActualise">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Currency
                  name="montantActualiseHt"
                  value={composant?.montantActualiseHt}
                  transparent
                  fluid
                  onChange={handleChangeCurrency}
                >
                  <input
                    data-testid={`montantActualiseHt-${composant?.identifiant}`}
                    className="currencyInput"
                  />
                </Currency>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Currency
                  name="montantActualiseTtc"
                  value={composant?.montantActualiseTtc}
                  transparent
                  fluid
                  onChange={handleChangeCurrency}
                >
                  <input
                    data-testid={`montantActualiseTtc-${composant?.identifiant}`}
                    className="currencyInput"
                  />
                </Currency>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ListeTable.Cell>
      </>

      )}
    </ListeTable.Row>
  );
};

export default ProjetComposantRow;
