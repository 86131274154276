import React from 'react';
import {
  Button,
  Card,
  Combobox,
  Grid,
} from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';

const nommagesDossiers = [
  { key: 1, text: 'Intitulé', value: 1 },
  { key: 2, text: 'Référence interne - Intitulé - par défaut', value: 2 },
  { key: 3, text: 'Intitulé (Référence interne)', value: 3 },
];

interface ReglesNommagesProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>, { name, value }: {
    name: string;
    value: string;
  }) => void,
  codeNommage: number
}

const ReglesNommages = ({ onChange, codeNommage }: ReglesNommagesProps) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>
        Règle de nommage du dossier bureautique lié à chaque projet
      </Card.Header>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={6}>
            <Combobox
              fluid
              menuPortalTarget={document.body}
              options={nommagesDossiers}
              selection
              onChange={onChange}
              name="leviiaCodeNommage"
              value={codeNommage}
            />

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>

          <Grid.Column>
            <Button floated="right" className="leviiaButton">
              <>
                <Icon
                  name="FolderSync"
                  iconSet="Lucide"
                />
                Mettre à jour le nom de tous les dossiers
              </>
              {/* TODO: action button */}
            </Button>

          </Grid.Column>
        </Grid.Row>
      </Grid>

    </Card.Content>
  </Card>
);

export default ReglesNommages;
