// @ts-nocheck

import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { Subvention } from '@/src/interfaces/subvention';
import { ProjetStatus } from '@/src/constants/projetStatus';

interface SubventionListeTableProps {
  items: Array<Subvention>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSelect: any,
  onSort: any,
  isExplorer: boolean
}

const SubventionListeTable = (props: SubventionListeTableProps) => {
  const {
    items,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelect,
    isExplorer,
  } = props;

  return (
    <ListeTable
      items={items}
      loading={loading}
      count={count}
      perPage={perPage}
      onLoad={onLoad}
      onSort={onSort}
      onSelect={onSelect}
    >
      <ListeTable.Header>
        <ListeTable.Column
          name="libelle"
          width={8}
          sortable
        >
          Libellé
        </ListeTable.Column>
      </ListeTable.Header>
      {
        isExplorer
        && (
          <ListeTable.Header>
            <ListeTable.Column
              name="libelleFinanceur"
              width={4}
              sortable
            >
              Financeur
            </ListeTable.Column>
            <ListeTable.Column
              name="libelleProjet"
              width={4}
              sortable
            >
              Projet
            </ListeTable.Column>
            <ListeTable.Column
              name="mntAttribue"
              sortable
              width={2}
            >
              Montant attribué
            </ListeTable.Column>
          </ListeTable.Header>
        )
      }
      {(subvention) => (
        <div className={subvention.projet.status === ProjetStatus.CLOTURE && 'cloturedProjetRow'}>
          <ListeTable.Row>
            <ListeTable.Cell
              name="libelle"
              data-testid={`subventionLibelle-${subvention?.identifiant}`}
            >
              {subvention?.libelle}
            </ListeTable.Cell>
            {
              isExplorer
              && (
                <>
                  <ListeTable.Cell
                    name="libelleFinanceur"
                  >
                    {subvention?.financeur.nom}
                    {' '}
                    {subvention?.financeur.prenom}
                  </ListeTable.Cell>
                  <ListeTable.Cell
                    name="libelleProjet"
                  >
                    {subvention?.projet?.libelle}
                  </ListeTable.Cell>
                  <ListeTable.Cell
                    name="mntAttribue"
                    textAlign="right"
                  >
                    {formatMontant(subvention?.mntAttribue)}
                  </ListeTable.Cell>
                </>
              )
            }
          </ListeTable.Row>
        </div>
      )}
    </ListeTable>
  );
};

export default SubventionListeTable;
