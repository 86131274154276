import React, { useState } from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import { Form, Header } from '@jvs-group/jvs-mairistem-composants';
import { useHistory } from 'react-router';
import SubventionListeTable from '@/src/components/SubventionListe/SubventionListeTable';
import { Subvention } from '@/src/interfaces/subvention';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';
import { Etiquette } from '@/src/interfaces/etiquette';

interface SubventionListeProps {
  subventions?: Array<Subvention>,
  count?: number,
  perPage?: number,
  loading?: boolean,
  onLoad?: any,
  onSearch?: any,
  onSort?: any,
  onSelected?: any,
  onExplored?: any,
  onExpanded?: any,
  state?: any,
  mode?: 'expander' | 'explorer',
  query?: any
}

const SubventionListe = (props: SubventionListeProps) => {
  const {
    subventions,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    query,
    ...handlers
  } = props;

  const [searchClosed, setSearchClosed] = useState(false);
  const [selectedEtiquettes, setSelectedEtiquettes] = useState([]);

  const handleQueryChange = (etiquettes: Etiquette[], isClosed: boolean = false) => {
    query?.filter(() => ({
      ...isClosed && { selectCloturedProject: true },
      ...(etiquettes.length > 0)
      && { 'etiquettesId[]': etiquettes.map((etiquette) => etiquette.identifiant) },
    }));
  };

  const handleChangeSearchClosed = () => {
    handleQueryChange(selectedEtiquettes, !searchClosed);
    setSearchClosed(!searchClosed);
  };

  const handleEtiquetteChange = ({ etiquettes }) => {
    setSelectedEtiquettes(etiquettes);
    handleQueryChange(etiquettes);
  };

  const history = useHistory();
  const handleClick = () => {
    history.push('/subventionsPercues');
  };

  return (
    <Liste
      title="Subventions"
      mode={mode}
      searchable
      search={{ placeholder: 'Rechercher sur le libellé, le financeur, le projet et le montant attribué' }}
      onExpand={handleClick}
      {...handlers}
    >
      <Liste.Header>
        {
          mode !== 'explorer' ? (
            <Header size="small">
              {count}
              {' '}
              {(count > 1) ? 'subventions' : 'subvention'}
            </Header>
          ) : (
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  // @ts-expect-error allowAdditions
                  allowAdditions={false}
                  control={EtiquettesInput}
                  identifiantEntite={sessionStorage.getItem('identifiantEntite')}
                  onChange={handleEtiquetteChange}
                  selectedEtiquettes={selectedEtiquettes}
                  dropdownProps={{ className: 'etiquettes' }}
                />
                <Form.Checkbox
                  className="filterCheckboxed"
                  label="Inclure les aides des projets soldés"
                  onChange={handleChangeSearchClosed}
                  checked={searchClosed}
                />
              </Form.Group>
            </Form>
          )
        }
      </Liste.Header>
      <Liste.Content>
        <SubventionListeTable
          items={subventions}
          loading={loading}
          count={count}
          perPage={perPage}
          onLoad={onLoad}
          onSort={onSort}
          onSelect={onSelected}
          isExplorer={mode === 'explorer'}
        />
      </Liste.Content>
    </Liste>
  );
};

export default SubventionListe;
