import React from 'react';
import { type DropdownItemProps } from '@jvs-group/jvs-mairistem-composants';
import { Combobox } from '@jvs-group/jvs-mairistem-finances-utils';

interface PlanningSearchDropdownProps {
  isMandat: boolean;
  mandatOptions: any;
  onMandatChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: DropdownItemProps) => void;
  onYearChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: DropdownItemProps) => void;
  selectedYear: number;
  yearOptions: any;
}

const PlanningSearchDropdown = ({
  isMandat,
  mandatOptions,
  onMandatChange,
  onYearChange,
  selectedYear,
  yearOptions,
}: PlanningSearchDropdownProps) => (
  <Combobox
    button
    className="icon"
    labeled
    text={isMandat
      ? `Projet courant sur l'exercice ${selectedYear} - ${selectedYear + 6}`
      : `Projet courant sur l'exercice ${selectedYear}`}
  >
    <Combobox.Menu>
      <Combobox.Header content="Filtrer par année" icon="calendar" />
      <Combobox.Divider />
      {yearOptions.map((option) => (
        <Combobox.Item
          key={option.key}
          {...option}
          onClick={onYearChange}
        />
      ))}
      <Combobox.Header content="Filtrer par mandature" icon="calendar" />
      <Combobox.Divider />
      {mandatOptions.map((option) => (
        <Combobox.Item
          key={option.key}
          {...option}
          onClick={onMandatChange}
        />
      ))}
    </Combobox.Menu>
  </Combobox>
);

export default PlanningSearchDropdown;
