import { ModalAssistant } from '@jvs-group/jvs-mairistem-assistant';
import React from 'react';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import type { Gabarit } from '@jvs-group/jvs-mairistem-finances-utils';
import {
  isNumber,
} from 'lodash';
import { DropdownOption } from '@jvs-group/jvs-mairistem-liste';
import EtapeModaliteAttribution
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeModaliteAttribution';
import { validateStepCaracteristique, validateStepModalite } from '@/src/utils/conditionVersement';
import EtapeCaracteristique
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeCaracteristique';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import EtapeJustificatif
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeJustificatif';
import { Step } from '@/src/interfaces/step';

export const STEP_CARACTERISTIQUE = 0;
export const STEP_MODALITE_ATTRIBUTION = 1;
export const STEP_JUSTIFICATIF = 2;

interface AssistantVersementsProps {
  conditionVersement: ConditionVersement,
  montantPrecedentesConditions: number,
  budgetOptions: DropdownOption[],
  open: boolean,
  onFinish: (condition: ConditionVersement) => void,
  // sert à mettre à jour le store directement sans validation de l'utilisateur
  onClose: () => void,
  onLibelleDatalist: () => string[],
  step: number,
  montantSubvention: number,
  gabaritsPj: Gabarit[],
}

const AssistantVersements = ({
  conditionVersement = null,
  montantPrecedentesConditions = 0,
  budgetOptions = null,
  open = false,
  onFinish = null,
  onClose = null,
  step: stepProps = null,
  montantSubvention = 0,
  onLibelleDatalist = null,
  gabaritsPj = null,
} : AssistantVersementsProps) => {
  const [versement, setVersement] = React.useState<ConditionVersement>(conditionVersement);

  const initialStep = stepProps ?? 0;
  const [currentStep, setCurrentStep] = React.useState<number>(initialStep);

  const [steps, setSteps] = React.useState<Step[]>([
    {
      title: 'Caractéristiques',
      dataTestId: 'boutonCaracteristiquesStep',
      updating: false,
    },
    {
      title: "Modalités d'attribution",
      dataTestId: 'boutonModalitesAttributionStep',
      updating: false,
    },
    {
      title: 'Justificatifs à fournir',
      dataTestId: 'boutonJustificatifsStep',
      required: false,
      updating: false,
    },
  ]);

  const isNewIdentifiant = React.useCallback(
    () => !isNumber(conditionVersement?.identifiant) && !conditionVersement?.isValidated,
    [conditionVersement?.isValidated, conditionVersement?.identifiant],
  );

  const initSteps = React.useCallback(() => {
    setSteps((oldSteps) => (
      [...(oldSteps?.map((step) => ({ ...step, error: null, validated: !isNewIdentifiant() })) ?? [])]
    ));
    setCurrentStep(initialStep);
  }, [initialStep, isNewIdentifiant]);

  React.useEffect(() => {
    initSteps();
  }, [initSteps, conditionVersement?.identifiant]);

  const handleSaveStep = React.useCallback((index) => {
    let stepResult = null;
    switch (index) {
      case STEP_CARACTERISTIQUE:
        stepResult = validateStepCaracteristique(versement);
        break;
      case STEP_MODALITE_ATTRIBUTION:
        stepResult = validateStepModalite(
          montantPrecedentesConditions,
          versement?.montant,
          versement?.dateButoire,
          montantSubvention,
        );
        break;
      default:
        return true;
    }
    stepResult.updating = false;

    setSteps((oldSteps) => ([
      ...(oldSteps?.map((step, i) => {
        if (i !== index) {
          return step;
        }

        return { ...step, ...stepResult };
      }) ?? []),
    ]));

    return stepResult.validated;
  }, [
    versement,
    montantPrecedentesConditions,
    montantSubvention,
  ]);

  const handleFinish = React.useCallback(() => {
    const isCaracteristiqueValid = steps[STEP_CARACTERISTIQUE].validated;
    const isAttributionValid = steps[STEP_CARACTERISTIQUE].validated;

    if (!isCaracteristiqueValid) {
      setCurrentStep(STEP_CARACTERISTIQUE);
    }

    if (!isAttributionValid) {
      setCurrentStep(STEP_MODALITE_ATTRIBUTION);
    }

    if (isAttributionValid
            && isCaracteristiqueValid
            && onFinish) {
      onFinish({
        ...versement,
        isValidated: true, // Permet de savoir que les étapes ont déjà été validées
      });
    }
  }, [
    onFinish,
    versement,
    steps,
  ]);

  const handleCurrentStepChange = (newStep) => {
    handleSaveStep(currentStep);
    setCurrentStep(newStep);
  };

  const handleChange = React.useCallback((condition, isDatabaseChanges = true) => {
    setSteps((oldSteps) => (
      [
        ...(oldSteps?.map((step, index) => {
          if (index === currentStep) {
            return {
              ...step,
              validated: !isDatabaseChanges,
              updating: isDatabaseChanges,
            };
          }

          return step;
        }) ?? []),
      ]
    ));

    setVersement((old) => ({
      ...old,
      ...condition,
    }));
  }, [currentStep]);

  const handleClose = () => {
    initSteps();
    if (onClose) onClose();
  };

  return (
    <ModalAssistant
      open={open}
      title="Versement"
      steps={steps}
      heightContent={window.innerHeight * 0.6}
      onFinish={handleFinish}
      onStepChange={handleCurrentStepChange}
      onSaveStep={handleSaveStep}
      onClose={handleClose}
      currentStep={currentStep}
      initialStep={STEP_CARACTERISTIQUE}
      data-testid="conditionVersementModal"
      autoUpdating={false}
    >
      <ModalAssistant.Content>
        <Form>
          {currentStep === STEP_CARACTERISTIQUE
                    && (
                    <EtapeCaracteristique
                      conditionVersement={versement}
                      montantPrecedentesConditions={montantPrecedentesConditions}
                      onLibelleDatalist={onLibelleDatalist}
                      montantSubvention={montantSubvention}
                      onChange={handleChange}
                      budgetOptions={budgetOptions}
                    />
                    ) }

          {currentStep === STEP_MODALITE_ATTRIBUTION
                    && (
                    <EtapeModaliteAttribution
                      conditionVersement={versement}
                      onChange={handleChange}
                      montantPrecedentesConditions={montantPrecedentesConditions}
                      montantSubvention={montantSubvention}
                    />
                    ) }

          {currentStep === STEP_JUSTIFICATIF
                    && (
                    <EtapeJustificatif
                      naturesConditions={versement?.naturesConditions}
                      deletedNaturesConditions={versement?.deletedNaturesConditions}
                      onChange={handleChange}
                      gabaritsPj={gabaritsPj}
                    />
                    ) }
        </Form>
      </ModalAssistant.Content>
    </ModalAssistant>
  );
};

export default AssistantVersements;
