// @ts-nocheck
import React from 'react';

import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { isNewIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Composant } from '@/src/interfaces/composant';
import ProjetComposantRow from '@/src/components/ProjetFichePanel/ProjetComposant/ProjetComposantRow';
import { ProjetStatus } from '@/src/constants/projetStatus';
import ProjetComposantFooter from '@/src/components/ProjetFichePanel/ProjetComposant/ProjetComposantFooter';

interface ProjetComposantProps {
    composants : Composant[],
    deletedComposants: number[],
    onChange: any,
    status: number,
}

const ProjetComposant = ({
  composants,
  onChange = null,
  deletedComposants = null,
  status = null,
} : ProjetComposantProps) => {
  const [errors, setErrors] = React.useState([]);
  const handleValidateClick = async (item, status) => {
    if (!item?.libelle) {
      setErrors([
        { field: 'libelle', itemId: item?.identifiant, message: 'Le libelle est obligatoire' },
      ]);

      return false;
    }

    switch (status) {
      case 'creating':
        onChange({
          composants: [
            ...composants,
            item,
          ],
        });
        break;
      case 'updating':
        onChange({
          composants: composants?.map((composant) => ({
            ...(composant?.identifiant === item?.identifiant ? item : composant),
          })),
        });
        break;
      default:
        break;
    }

    return true;
  };

  const handleDeleteClick = async (item) => {
    if (composants?.length === 1) {
      toast.error('Le projet doit avoir au moins un composant');
      return;
    }

    onChange({
      composants: composants?.filter((composant) => composant?.identifiant !== item?.identifiant),
      deletedComposants: [
        ...deletedComposants,
        ...(!isNewIdentifiant(item?.identifiant) && [item?.identifiant]),
      ],
    });

    setErrors(null);
  };

  const handleCancelClick = () => setErrors([]);

  const renderHeader = () => {
    const montantEstimeWidth = status !== ProjetStatus.CONCEPTION ? 3 : 9;
    const headers = [
      (<ListeTable.Column name="libelle" required width={7} textAlign="left">Composante</ListeTable.Column>),
      (<ListeTable.Column name="montantEstime" textAlign="right" width={montantEstimeWidth}> Montant estimé </ListeTable.Column>),
    ];

    if (status !== ProjetStatus.CONCEPTION) {
      headers.push(
        (<ListeTable.Column name="montantInitial" textAlign="right" width={3}> Montant contractualisé </ListeTable.Column>),
        (<ListeTable.Column name="montantActualise" textAlign="right" width={3}>Montant actualisé</ListeTable.Column>),
      );
    }

    return (
      <ListeTable.Header>
        {headers.map((header) => header)}
      </ListeTable.Header>
    );
  };

  const renderFooter = () => {
    if (composants?.length <= 1) {
      return null;
    }

    return (
      <ListeTable.Footer key="total">
        <ProjetComposantFooter composants={composants} status={status} />
      </ListeTable.Footer>
    );
  };

  return (
    <div className="composants">
      <ListeTableContainer
        onCancelClick={handleCancelClick}
        onValidateClick={handleValidateClick}
        onDeleteClick={handleDeleteClick}
        allowModifications
        allowDeletions
        allowAdditions
        additionLabel="Ajouter une composante"
        errors={errors}
        items={composants}
      >
        <ListeTable
          loading={_.isUndefined(composants)}
          virtual={false}
          scrollable={false}
        >
          {renderHeader()}
          {(composant, invalidate, rowProps, utilities) => (
            <ProjetComposantRow
              key={composant?.identifiant}
              composant={composant}
              rowProps={rowProps}
              utilities={utilities}
              errors={errors}
              status={status}
            />
          )}

          {renderFooter()}
        </ListeTable>
      </ListeTableContainer>
    </div>
  );
};

export default ProjetComposant;
