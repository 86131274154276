import React from 'react';

import { Fiche, useFicheServiceStatus } from '@jvs-group/jvs-mairistem-fiche';
import { ErrorField } from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import { PieceJointeBar } from '@jvs-group/jvs-mairistem-comptabilite';
import {
  Fichiers,
  TYPE_GABARIT,
  getNatureFromGabarit,
  useGabaritPj,
} from '@jvs-group/jvs-mairistem-finances-utils';
import SubventionInformation
  from '@/src/components/SubventionFichePanel/Generalite/SubventionInformation/SubventionInformation';
import ConditionsVersements from '@/src/components/SubventionFichePanel/ConditionsVersements/ConditionsVersements';
import EcrituresComptables from '@/src/components/Common/EcrituresComptables/EcrituresComptables';
import subventionService from '@/src/services/subventionService';
import Commentaires from '@/src/components/Common/Commentaires';
import { Subvention } from '@/src/interfaces/subvention';
import { Composant } from '@/src/interfaces/composant';
import { getMontantDepensesEligibles } from '@/src/utils/subvention';
import SubventionAttribution
  from '@/src/components/SubventionFichePanel/Generalite/SubventionAttribution/SubventionAttribution';
import SubventionListeContainer from '@/src/components/SubventionListe/SubventionListeContainer';

interface SubventionFicheConsumerProps {
  subvention?: Subvention,
  mutation?: any,
  errors?: Array<ErrorField>
}

const SubventionFicheConsumer = ({
  subvention,
  mutation = null,
  errors = null,
  ...rest
} : SubventionFicheConsumerProps) => {
  const {
    financeur,
    libelle,
    identifiantEntite = parseInt(sessionStorage.getItem('identifiantEntite'), 10),
    projet,
    referenceDossier,
    dateDebut,
    mntAttribue,
    etiquettes,
    etiquettesProjet,
    cloture,
    identifiant,
    realisationHT,
    plafondEligible,
    tauxAide,
    montantEnvisage,
    assietteEligible,
    composants,
  } = subvention || {};

  const ficheServiceStatus = useFicheServiceStatus(subventionService, identifiant);
  const [, gabaritsPj] = useGabaritPj([TYPE_GABARIT.SUBVENTION_PERCUE, TYPE_GABARIT.PROJET]);

  const handleChange = React.useCallback((e, isMerge = false) => {
    if (isMerge) {
      mutation?.merge({
        ...subvention,
        ...e,
      });
    } else {
      mutation?.update(e);
    }
  }, [mutation, subvention]);

  const handleChangePj = React.useCallback((changes) => {
    handleChange({
      identifiant,
      ...changes,
    });
  }, [identifiant, handleChange]);

  const information = React.useMemo(() => (
    <Fiche.Panel
      key="generalite"
      id="generalite"
      title="GÉNÉRALITÉS"
    >
      <SubventionInformation
        identifiant={identifiant}
        financeur={financeur}
        libelle={libelle}
        identifiantEntite={identifiantEntite}
        projet={projet}
        etiquettes={etiquettes}
        etiquettesProjet={etiquettesProjet}
        isCloture={cloture}
        realisationHT={realisationHT}
        plafondEligible={plafondEligible}
        tauxAide={tauxAide}
        montantEnvisage={montantEnvisage}
        assietteEligible={assietteEligible}
        errors={errors}
        onChange={handleChange}
        composants={composants}
      />
    </Fiche.Panel>
  ), [
    financeur,
    libelle,
    identifiantEntite,
    projet,
    etiquettes,
    etiquettesProjet,
    cloture,
    identifiant,
    realisationHT,
    errors,
    composants,
    montantEnvisage,
    assietteEligible,
    plafondEligible,
    tauxAide,
    handleChange,
  ]);

  const attribution = React.useMemo(() => (
    <Fiche.Panel
      key="attribution"
      id="attribution"
      title="ATTRIBUTION"
    >
      <SubventionAttribution
        identifiant={identifiant}
        referenceDossier={referenceDossier}
        dateDebut={dateDebut}
        mntAttribue={mntAttribue}
        conditionsVersements={subvention?.conditionsVersements}
        errors={errors}
        onChange={handleChange}
      />
    </Fiche.Panel>
  ), [
    identifiant,
    referenceDossier,
    dateDebut,
    mntAttribue,
    subvention?.conditionsVersements,
    errors,
    handleChange,
  ]);

  const conditionsVersements = React.useMemo(() => (
    <Fiche.Panel key="conditions" id="conditions" title="CONDITIONS DE VERSEMENT">
      <ConditionsVersements
        conditionsVersements={subvention?.conditionsVersements}
        subvention={subvention}
        montantSubvention={subvention?.mntAttribue}
        onChange={handleChange}
        identifiant={subvention?.identifiant}
        identifiantEntite={identifiantEntite}
        deletedConditionsVersements={subvention?.deletedConditionsVersements}
        gabaritsPj={gabaritsPj}
      />
    </Fiche.Panel>
  ), [
    identifiantEntite,
    subvention,
    handleChange,
    gabaritsPj,
  ]);

  const ecrituresComptables = React.useMemo(() => (
    <Fiche.Panel key="ecritures" id="ecritures" title="ECRITURES COMPTABLES">
      <EcrituresComptables
        projet={subvention?.projet}
        conditionsVersements={subvention?.conditionsVersements}
        montantSubvention={subvention?.mntAttribue}
        realisationHT={realisationHT}
        composants={composants as Composant[]}
        montantProjet={getMontantDepensesEligibles(composants as Composant[], realisationHT)}
      />
    </Fiche.Panel>
  ), [
    subvention?.projet,
    subvention?.conditionsVersements,
    subvention?.mntAttribue,
    realisationHT,
    composants,
  ]);

  const commentaires = React.useMemo(() => (
    <Fiche.Panel key="commentaires" id="commentaires" title="COMMENTAIRES">
      <Commentaires
        identifiant={subvention?.identifiant}
        commentaire={subvention?.commentaire}
        infosPubliques={subvention?.infosPubliques}
        onChange={handleChange}
      />
    </Fiche.Panel>
  ), [
    subvention?.identifiant,
    handleChange,
    subvention?.commentaire,
    subvention?.infosPubliques,
  ]);

  const getPjSideBar = React.useCallback(() => (
    <PieceJointeBar
      identifiantFichierLie={identifiant}
      nomFichierLie={Fichiers.SUBVENTION_PERCUE}
      types={getNatureFromGabarit(gabaritsPj?.find((g) => g.type === TYPE_GABARIT.SUBVENTION_PERCUE))}
      onChange={handleChangePj}
      typeGabarit={TYPE_GABARIT.SUBVENTION_PERCUE}
    />
  ), [
    identifiant,
    handleChangePj,
    gabaritsPj,
  ]);

  let header = '';

  if (ficheServiceStatus.created && !ficheServiceStatus.updated) {
    header = 'Nouvelle subvention';
  } else {
    header = `${subvention?.libelle || ''}`;
  }

  const sidebars = React.useMemo(() => ({
    left: <SubventionListeContainer />,
    right: {
      content: getPjSideBar(),
      icon: 'attach',
      alwaysPinned: true,
      width: '15vw',
    },
  }), [getPjSideBar]);

  return (
    <span
      className="ficheWithSideBar"
    >
      <Fiche
        type="subvention"
        header={header}
        errors={errors}
        sidebars={sidebars}
        {...rest}
      >
        {information}
        {attribution}
        {conditionsVersements}
        {ecrituresComptables}
        {commentaires}
      </Fiche>
    </span>
  );
};

export default SubventionFicheConsumer;
