import React from 'react';
import {
  Button,
  Card,
  Grid,
  Input,
} from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';

interface DossierRacineProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>, { name, value }: {
    name: string;
    value: string;
  }) => void,
  nomDossierRacine: string
}

const DossierRacine = ({ onChange, nomDossierRacine }: DossierRacineProps) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>
        Défintion du dossier racine Projets
      </Card.Header>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={6}>
            <Input
              fluid
              name="leviiaRacine"
              value={nomDossierRacine}
              onChange={onChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button floated="right" className="leviiaButton">
              <>
                <Icon
                  name="FolderSync"
                  iconSet="Lucide"
                />
                Déplacer tous les dossiers enfants existants
              </>
              {/* TODO: action button */}
            </Button>

          </Grid.Column>
        </Grid.Row>
      </Grid>

    </Card.Content>
  </Card>
);

export default DossierRacine;
