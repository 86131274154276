import React from 'react';

import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import {
  Grid, Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { Composant } from '@/src/interfaces/composant';
import { ProjetStatus } from '@/src/constants/projetStatus';

interface ProjetComposantFooterProps {
    composants : Composant[],
    status : number,
}

const ProjetComposantFooter = ({
  composants = [],
  status,
} : ProjetComposantFooterProps) => {
  const montants = composants?.reduce((prev, curr) => ({
    montantEstimeHt: prev.montantEstimeHt + (curr.montantEstimeHt ?? 0),
    montantEstimeTtc: prev.montantEstimeTtc + (curr.montantEstimeTtc ?? 0),
    montantInitialHt: prev.montantInitialHt + (curr.montantInitialHt ?? 0),
    montantInitialTtc: prev.montantInitialTtc + (curr.montantInitialTtc ?? 0),
    montantActualiseHt: prev.montantActualiseHt + (curr.montantActualiseHt ?? 0),
    montantActualiseTtc: prev.montantActualiseTtc + (curr.montantActualiseTtc ?? 0),
  }), {
    montantEstimeHt: 0,
    montantEstimeTtc: 0,
    montantInitialHt: 0,
    montantInitialTtc: 0,
    montantActualiseHt: 0,
    montantActualiseTtc: 0,
  });
  return (
    <>
      <ListeTable.Cell name="libelle">
        <Grid padded={false}>
          <Grid.Row stretched>
            <Grid.Column width={8} className="bold">
              TOTAL
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Segment basic compact className="no-padding">HT</Segment>
              <Segment basic compact className="no-padding">TTC</Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ListeTable.Cell>

      <ListeTable.Cell name="montantEstime" textAlign="right">
        <Grid>
          <Grid.Row stretched>
            <Grid.Column verticalAlign="middle">
              {formatMontant(montants?.montantEstimeHt, null, false)}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column verticalAlign="middle">
              {formatMontant(montants?.montantEstimeTtc, null, false)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ListeTable.Cell>

      {status !== ProjetStatus.CONCEPTION && (
      <>
        <ListeTable.Cell name="montantInitial" textAlign="right">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {formatMontant(montants?.montantInitialHt, null, false)}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {formatMontant(montants?.montantInitialTtc, null, false)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ListeTable.Cell>
        <ListeTable.Cell name="montantActualise" textAlign="right">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {formatMontant(montants?.montantActualiseHt, null, false)}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {formatMontant(montants?.montantActualiseTtc, null, false)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ListeTable.Cell>
      </>

      )}
    </>
  );
};

export default ProjetComposantFooter;
